<!--
 * @Description:  
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-09-11 15:19:51
 * @LastEditors: Seven
 * @LastEditTime: 2022-03-29 14:31:07
-->
<template>
<div>
	<el-form-item prop="cashierId" style="width: 282px;margin-bottom: 35px">
		<v-autocomplete
			v-model="dropoutForm.cashierId"
			label="出纳审核"
			:items="cashierCheckList"
			:menu-props="{ bottom: true, offsetY: true }"
			item-text="userName"
			item-value="tyUserId"
			placeholder="选择校区出纳"
			outlined
			:dense="true"
			:height="42"
			:clearable="true"
			clear-icon="$clear"
			hide-details="auto"
		>
    </v-autocomplete>
	</el-form-item>
	<el-form-item prop="reason" class="Contextclass">
		<v-textarea
			v-model.trim="dropoutForm.reason"
			hide-details="auto"
			label="申请原因"
			placeholder="售后申请说明，500字内"
			outlined
			:dense="true"
			:height="120"
			maxlength="500"
			counter
		></v-textarea>
	</el-form-item>
</div>
</template>

<script>
export default {
	props: {
		dropoutForm: Object,
		cashierCheckList: Array,
	}
}
</script>

<style>
</style>