var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        {
          staticStyle: { width: "282px", "margin-bottom": "35px" },
          attrs: { prop: "cashierId" }
        },
        [
          _c("v-autocomplete", {
            attrs: {
              label: "出纳审核",
              items: _vm.cashierCheckList,
              "menu-props": { bottom: true, offsetY: true },
              "item-text": "userName",
              "item-value": "tyUserId",
              placeholder: "选择校区出纳",
              outlined: "",
              dense: true,
              height: 42,
              clearable: true,
              "clear-icon": "$clear",
              "hide-details": "auto"
            },
            model: {
              value: _vm.dropoutForm.cashierId,
              callback: function($$v) {
                _vm.$set(_vm.dropoutForm, "cashierId", $$v)
              },
              expression: "dropoutForm.cashierId"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "Contextclass", attrs: { prop: "reason" } },
        [
          _c("v-textarea", {
            attrs: {
              "hide-details": "auto",
              label: "申请原因",
              placeholder: "售后申请说明，500字内",
              outlined: "",
              dense: true,
              height: 120,
              maxlength: "500",
              counter: ""
            },
            model: {
              value: _vm.dropoutForm.reason,
              callback: function($$v) {
                _vm.$set(
                  _vm.dropoutForm,
                  "reason",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "dropoutForm.reason"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }